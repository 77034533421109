<div class="panel-logo">
    <img src="assets/img/logo-royal-blue.png" alt="logo">
</div>
<div class="about-us-widget">
    <h5 class="panel-widget-title">About Us</h5>
    <p>
        Sed perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam rem aperams
        eaque ipsa quae abillo inventore veritatis
    </p>
</div>
<div class="contact-us">
    <h5 class="panel-widget-title">Contact Us</h5>
    <ul>
        <li>
            <i class="far fa-map-marker-alt"></i>
            354 Oakridge, Camden NJ 08102 - USA
        </li>
        <li>
            <i class="far fa-envelope-open"></i>
            <span><a href="mailto:example@example.com">example@example.com</a></span>
            <span><a href="www.example.net" target="_blank">www.example.net</a></span>
        </li>
        <li>
            <i class="far fa-phone"></i>
            <span><a href="tel:+01234567899">+012 (345) 678 99</a></span>
            <span><a href="tel:+8563214">+8563214</a></span>
        </li>
    </ul>
</div>